<template>
  <div>
    <div class="img-container tiny">
      <div>
        <h2>Installer l'application !</h2>
        <p>
          Afin de pouvoir utiliser l'application en mode hors connexion,
          vous pouvez installer l'application sur votre appareil.
        </p>
        <img :src="require('@/assets/app.png', )" />
      </div>
    </div>
    <div v-if="pwaDeferredPrompt">
      <div class="button-container">
        <button @click="promptInstall">Installer</button>
      </div>
      <div class="button-container">
        <button
          class="secondary"
          @click="back"
        >
          Continuer sur le site
        </button>
      </div>
    </div>
    <div
      v-else
      id="pwa_instructions"
    >
      <div
        v-if="nav === 'chrome'"
        class="chrome"
      >
        <p>Rendez-vous dans le menu situé en haut à droite de votre navigateur</p>
        <img :src="require('@/assets/pwa_instructions/menu1.png', )"/>
        <p>
          puis cliquez sur le bouton suivant: <br />
          "Installer l'application" ou "Ajouter à l'écran d'accueil"
        </p>
        <img :src="require('@/assets/pwa_instructions/menu2.png', )"/>
        <p>
          Vous serez ensuite invité à installer l'application.<br />
          <i>Cela peut prendre quelques minutes.</i>
        </p>
        <img :src="require('@/assets/pwa_instructions/menu3.png', )"/>
        <p>Enfin, vous la retrouverez dans votre liste d'applications</p>
      </div>
      <div
        v-else-if="nav === 'safari'"
        class="safari"
      >
        <p>Rendez-vous dans le menu situé en bas de votre navigateur</p>
        <img :src="require('@/assets/pwa_instructions/menu4.png', )"/>
        <p>puis cliquez sur le bouton "Ajouter à l'écran d'accueil" ou "Sur l'écran d'accueil"</p>
        <img :src="require('@/assets/pwa_instructions/menu5.png', )"/>
        <p>
          Vous serez ensuite invité à installer l'application.<br />
          <i>Cela peut prendre quelques minutes.</i>
        </p>
        <img :src="require('@/assets/pwa_instructions/menu6.png', )"/>
        <p>Enfin, vous la retrouverez dans votre liste d'applications</p>
      </div>
      <div
        v-else-if="nav === 'firefox'"
        class="firefox"
      >
        <p>Rendez-vous dans le menu situé en bas de votre navigateur</p>
        <img :src="require('@/assets/pwa_instructions/menu1.png', )"/>
        <p>puis cliquez sur le bouton "Installer"</p>
        <p>
          Vous serez ensuite invité à installer l'application.<br />
          <i>Cela peut prendre quelques minutes.</i>
        </p>
        <p>Enfin, vous la retrouverez dans votre liste d'applications</p>
      </div>
      <div v-else>
        <p>Rendez-vous dans le menu de votre navigateur</p>
        <p>puis cliquez sur le bouton "Installer l'application" ou "Ajouter à l'écran d'accueil" </p>
        <p>
          Vous serez ensuite invité à installer l'application.<br />
          <i>Cela peut prendre quelques minutes.</i>
        </p>
        <p>Enfin, vous la retrouverez dans votre liste d'applications</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import { fnBrowserDetect } from "@/utils";

export default {
  name: "Install",
  computed: {
    ...mapGetters(["pwaDeferredPrompt"]),
  },
  data() {
    return {
      nav: "chrome", // fnBrowserDetect(),
    };
  },
  mounted() {
  },
  methods: {
    promptInstall() {
      this.pwaDeferredPrompt.prompt();
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
#pwa_instructions {
  margin-bottom: 3rem;
  img {
    max-width: 100%;
  }
  p {
    text-align: center;
  }
  .chrome, .firefox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img {
      &:nth-of-type(1), &:nth-of-type(4) {
        width: 10rem;
      }
      &:nth-of-type(2) {
        width: 20rem;
      }
      &:nth-of-type(3) {
        width: 25rem;
      }
      height: auto;
      display: block;
    }
  }
}
</style>
