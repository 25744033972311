<template>
  <div id="photos">
    <h2>Tes photos</h2>
    <div class="button-container">
      <label
        for="file-upload"
        class="btn"
      >
        Prendre une photo
      </label>
      <input
        id="file-upload"
        type="file"
        @change="onFileChange"
      />
    </div>
    <ul>
      <li
        v-for="photo in photos"
        :key="photo.id"
      >
        <a
          :href="photo.img"
          class="blue-link"
          target="blank"
        >
          <span>
            {{photo.date_creation | moment("dddd DD/MM/YY [à] HH:mm:ss")}}
          </span>
          <div>
            <template v-if="photo.seance">
              {{photo.seance.nom}}
            </template>
            <template v-else-if="photo.pesee">
              Pesée
            </template>
          </div>
          <MenuDropdown
            :options="[
              {
                title: 'Supprimer',
                handler: () => handleDelete(photo, ),
                icon: 'fa-chart-line',
              },
            ]"
          />
        </a>
      </li>
    </ul>
    <Pagination
      v-model="currentPage"
      :total-results="resultsCount"
      @paginate="getInfos"
    />
  </div>
</template>

<script>
import Api from "@/modules/axios";
import Pagination from "@/components/Pagination.vue";
import MenuDropdown from "@/components/MenuDropdown.vue";

export default {
  name: "photos",
  components: {
    Pagination,
    MenuDropdown,
  },
  data() {
    return {
      photos: [],
      currentPage: 1,
      resultsCount: 0,
    };
  },
  mounted() {
    this.getInfos();
  },
  methods: {
    getInfos() {
      this.loading = true;

      Api()
        .get(`/photo/?page=${this.currentPage}&page_size=15`)
        .then((res) => {
          this.photos = res.data.results;
          this.resultsCount = res.data.count;
          this.loading = false;
        });
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }

      const data = new FormData();
      data.append("img", files[0]);

      Api().post("/photo/", data)
        .then(() => {
          this.$toast("Photo enregistrée");
          this.getInfos();
        });
    },
    handleDelete(photo) {
      Api().delete(`/photo/${photo.id}/`)
        .then(() => {
          this.$toast("Photo supprimée");
          this.getInfos();
        });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

#photos {
  li + li {
    margin-top: 1rem;
  }
  li > a {
    text-decoration: none;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background:var.$background-gray;
    padding: 1rem;
    border-radius: var.$border-radius;
    cursor: pointer;
    span {
      display: inline-block;
      &:first-letter {
        text-transform: capitalize;
      }
    }
    & > * {
      flex: 1;
      text-align: center;
    }
    .v-popover {
      flex: 0;
    }
  }
  .button-container {
    margin: 1rem 0;
  }
  #file-upload {
    display: none;
  }
}
</style>
