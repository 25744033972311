<template>
  <div>
    <div class="scan-btn-container">
      <router-link :to="{name: 'suivi', }">
        <button
          class="btn"
          @click="back"
        >
          <i class="fa fa-arrow-left" />&nbsp;Revenir au suivi
        </button>
      </router-link>
      <button
        v-if="partages.length === 0 || newShareMode"
        class="btn"
        @click="scan"
      >
        <i class="fa fa-camera" />&nbsp;Scanner
      </button>
      <button
        v-else
        class="btn"
        @click="newShareMode = true"
      >
        <i class="fa fa-plus" />&nbsp;Nouveau
      </button>
    </div>
    <h2>Partage de suivi d'entrainement</h2>
    <PulseLoader
      v-if="loading"
      loading
    />
    <div
      v-else-if="partages.length && !newShareMode"
      class="partages-list"
    >
      <div v-if="partagesToMe.length">
        <label>J'ai accès à:</label>
        <ul>
          <li
            v-for="p in partagesToMe"
            :key="p.id"
            @click="goToStats(p.executant.id, )"
          >
            {{ p.executant.username }}
            <i
              class="fa fa-times"
              @click.stop="deletePartage(p.id, )"
            />
          </li>
        </ul>
      </div>
      <div v-if="partagesFromMe.length">
        <label>J'ai partagé à:</label>
        <ul>
          <li
            v-for="p in partagesFromMe"
            :key="p.id"
          >
            {{ p.beneficiaire.username }}
            <i
              class="fa fa-times"
              @click.stop="deletePartage(p.id, )"
            />
          </li>
        </ul>
      </div>
    </div>
    <div v-else-if="readerMode">
      <QrcodeStream @decode="handleScanSuccess" />
      <div class="button-container">
        <button @click="readerMode = false;">Annuler</button>
      </div>
    </div>
    <div v-else>
      <p>
        Ce QRCode permet de partager les performances de chaques séances réalisées.
      </p>
      <div id="qrcode">
        <VueQrcode
          :text="`follow-${user.id}`"
          width="100%"
          :correctLevel="3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Api from "@/modules/axios";
import { catchError } from "@/utils";
import VueQrcode from "vue-qr";

export default {
  name: "ShareSuivi",
  components: {
    QrcodeStream: () => import("vue-qrcode-reader").then((res) => res.QrcodeStream),
    VueQrcode,
  },
  data() {
    return {
      readerMode: false,
      qr: null,
      partages: [],
      newShareMode: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    partagesToMe() {
      return this.partages.filter((p) => p.beneficiaire.id === this.user.id);
    },
    partagesFromMe() {
      return this.partages.filter((p) => p.executant.id === this.user.id);
    },
  },
  mounted() {
    this.getPartages();
  },
  methods: {
    back(event) {
      if (this.newShareMode) {
        event.preventDefault();
        this.newShareMode = false;
      }
    },
    getPartages() {
      this.loading = true;
      Api().get("/partage_suivi/")
        .then((res) => {
          this.$set(this, "partages", res.data);
          this.loading = false;
        });
    },
    stopScan() {
      if (this.reader) {
        this.reader.stop();
        this.reader = null;
      }
    },
    scan() {
      this.readerMode = true;
    },
    handleScanSuccess(token) {
      const userId = token.replace("follow-", "");
      this.readerMode = false;
      Api().post("/partage_suivi/", { beneficiaire: this.user.id, executant: userId })
        .then(() => {
          this.$toast("Partage effectué");
          this.getPartages();
        })
        .catch(catchError);
    },
    deletePartage(id) {
      Api().delete(`/partage_suivi/${id}/`)
        .then(() => {
          this.$set(this, "partages", this.partages.filter((se) => se.id !== id));
          this.$toast("Partage supprimé");
        })
        .catch(catchError);
    },
    goToStats(userId) {
      this.$router.push({ name: "shared-suivi", params: { id: userId } });
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/variables.scss" as var;

.suivi-container {
  display: flex;
  justify-content: space-around;
  & > div > div {
    padding: 2rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var.$background-gray;
    border-radius: var.$border-radius;
    font-weight: bold;
    .fas {
      font-size: 3rem;
      margin-top: 2rem;
    }
  }
}
p {
  text-align: center;
}
#qrcode {
  display: flex;
  justify-content: center;
}
.scan-btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.partages-list {
  label {
    font-weight: bold;
    text-decoration: underline;
  }
  ul {
    margin: 2rem 0;
  }
  li {
    background: var.$background-gray;
    padding: 1rem;
    border-radius: var.$border-radius;
    text-align: center;
    & + li {
      margin-top: .5rem;
    }
  }
  .fa-times {
    color: var.$color-error;
    margin-left: 1rem;
  }
}
</style>
