<template>
  <div id="pricing">
    <h2>Abonnement</h2>
    <div class="adherent">
      <div>
        <div>Non-adhérent</div>
        <div class="switch">
          <input
            type="checkbox"
            id="adherent"
            v-model="isAdherent"
          />
          <span class="slider round" />
        </div>
        <div>Adhérent</div>
      </div>
    </div>
    <div id="forfaits">
      <button @click="selectedForfait = 'free'">
        <i
          v-if="selectedForfait === 'free'"
          class="fa fa-check"
        />
        <h3>Gratuit</h3>
        <p class="price">
          0,00&nbsp;€
        </p>
        <ul>
          <li v-if="isAdherent">Choisir la musique sur la radio</li>
          <li>Visualisation des séances publiques</li>
          <li>Visualisation des exercice</li>
        </ul>
      </button>
      <button @click="selectedForfait = 'premium'">
        <i
          v-if="selectedForfait === 'premium'"
          class="fa fa-check"
        />
        <h3>Premium</h3>
        <p class="price">
          {{isAdherent ? "1,99" : "3,99"}}&nbsp;€
          <span class="mois">/ mois</span>
        </p>
        <ul>
          <li>Création de séances</li>
          <li>Création d'exercice</li>
          <li>Sauvegarde des performances</li>
          <li>Temps de repos chronométrés</li>
          <li>Historique sur les performances</li>
          <li>Suivre les performances d'un autre sportif</li>
          <li>et bien plus encore ...</li>
        </ul>
      </button>
    </div>
    <div class="button-container">
      <button :disabled="selectedForfait === 'free'">S'abonner</button>
    </div>
  </div>
</template>

<script>

export default {
  name: "Pricing",
  data() {
    return {
      isAdherent: true,
      selectedForfait: "free",
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

#pricing {
  #forfaits {
    margin-top: 2rem;
    & > button {
      display: block;
      width: 100%;
      position: relative;
      border: 1px solid transparent;
      border-radius: var.$border-radius;
      padding: 1rem;

      &:nth-of-type(1) {
        background: var.$background-gray;
        border-color: var.$gray-dark;
      }
      &:nth-of-type(2) {
        border-color: var.$red;
        background: #ffbbbf;
      }

      h3 {
        margin: 0 0 0.5rem 0;
        text-align: center;
        font-size: 1.3rem;
      }
      margin-top: 1rem;
      ul {
        li {
          &:before {
            content: '✓';
            margin-right: 0.5rem;
          }
          + li {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
  .price {
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 1.5rem 0;
    .mois {
      font-size: 1rem;
      margin-left: 0.5rem;
    }
  }
  .fa-check {
    position: absolute;
    right: -1rem;
    top: -1rem;
    background: white;
    border: 1px solid var.$border-gray;
    border-radius: 50%;
    padding: 0.8rem;
    color: green;
    font-size: 1.2rem;
  }
  .adherent {
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      display: flex;
      align-items: center;
    }
    .switch {
      margin: 0 1rem;
    }
  }
}
</style>
