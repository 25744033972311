<template>
  <div>
    <h2>Calendrier des séances réalisées</h2>
    <VueCalendar
      ref="calendar"
      id="custom_calendar"
      :masks="masks"
      :attributes="attributes"
      disable-page-swipe
      is-expanded
      @update:from-page="getSeances"
    >
      <template v-slot:day-content="{ day, attributes, }">
        <div class="flex flex-col h-full z-10 overflow-hidden">
          <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
          <div class="flex-grow overflow-y-auto overflow-x-auto">
            <p
              v-for="attr in attributes"
              :key="attr.key"
              :class="attr.customData.class"
              >
              <img
                class="icon"
                v-if="attr.customData.icon"
                :src="require(`@/assets/muscles/${attr.customData.icon}.png`, )"
                v-tooltip="{
                  trigger: 'hover focus click',
                  content: attr.customData.title,
                }"
              />
              <i
                v-else
                v-tooltip="{
                  trigger: 'hover focus click',
                  content: attr.customData.title,
                }"
              >
                1
              </i>
              <!-- class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1" -->
              <!-- {{ attr.customData.title }} -->
            </p>
          </div>
        </div>
      </template>
    </VueCalendar>
  </div>
</template>

<script>
import VueCalendar from "v-calendar/lib/components/calendar.umd";
import Api from "@/modules/axios";
import moment from "moment";

export default {
  name: "Calendar",
  components: {
    VueCalendar,
  },
  data() {
    return {
      masks: {
        weekdays: "WWW",
      },
      attributes: [],
    };
  },
  mounted() {
    this.getSeances();
  },
  methods: {
    getSeances() {
      if (!this.$refs.calendar) {
        return false;
      }

      const days = this.$refs.calendar.getPageDays();
      const start = moment(days.at(0).date).format("Y-MM-DD");
      const end = moment(days.at(-1).date).format("Y-MM-DD");

      this.attributes = [];
      return Api()
        .get(`/performance/calendar/?start=${start}&end=${end}`)
        .then((res) => {
          console.log(res.data.results);
          this.attributes = res.data.results.map((r, index) => {
            const date = new Date(r.day);
            return {
              key: index,
              dates: date,
              customData: {
                icon: r.icon,
                title: r.title,
              },
            };
          });
        });
    },
  },
};
</script>

<style lang="scss">
#custom_calendar {
  min-width: 100%;
  max-width: 100%;

  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;

  .icon {
    width: 2rem;
  }
  .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  .vc-weeks {
    padding: 0;
  }
  .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    background-color: white;

    & > div {
      text-align: center;
      font-weight: bold;
    }

    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>
