<template>
  <div class="suivi">
    <div class="seances">
      <router-link :to="{name: 'suivi-share', }">
        <button class="btn right">
          <i class="fa fa-share-alt" /> Partages
        </button>
      </router-link>
      <h2>
        Suivi d'entrainement<br />
        <span v-if="sharedUserId">de {{sharedUser.first_name}} {{sharedUser.last_name}}</span>
      </h2>
      <label>
        Dernières performances:
      </label>
      <PulseLoader
        v-if="loading"
        loading
      />
      <template v-else-if="results.length">
        <ul id="perfs">
          <li
            v-for="r in results"
            :key="r.id"
            :class="{
              'new-day': r.newDay,
              'new-exo': r.newExo,
            }"
          >
            <span>
              {{ r.date_creation | moment("dddd DD/MM/YY [à] HH:mm:ss") }} <br />
            </span>
            <span>
              {{ r.exercice.nom }}
            </span>
            <span>
              Série {{ r.serie }}
            </span>
            <span>
              {{ trimDecimalsIfNeeded(r.poids, ) }}kg<br />
              {{ r.nb_repetition }} reps
            </span>
          </li>
        </ul>
        <Pagination
          v-model="currentPage"
          :total-results="resultsCount"
          @paginate="fetch"
        />
      </template>
      <p v-else>Aucune séance réalisée</p>
    </div>
  </div>
</template>

<script>
import Api from "@/modules/axios";
import { trimDecimalsIfNeeded } from "@/utils";
import Pagination from "@/components/Pagination.vue";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "Suivi",
  components: {
    Pagination,
  },
  data() {
    return {
      currentPage: 1,
      resultsCount: 1,
      results: [],

      currentSeance: null,
      sharedUser: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    sharedUserId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.$store.commit("setAvatarShow", false);
    this.fetch();
  },
  methods: {
    trimDecimalsIfNeeded,
    fetch() {
      const url = `/performance/?user_created=${
        this.sharedUserId || this.user.id
      }&page=${this.currentPage}&ordering=-date_creation&page=${this.currentPage}`;

      this.loading = true;
      if (this.sharedUserId) {
        Api().get(`/utilisateur/${this.sharedUserId}/`)
          .then((res) => {
            this.sharedUser = res.data;
          });
      }
      Api().get(url)
        .then((res) => {
          let lastDay = null;
          let lastExo = null;

          this.results = res.data.results.map((r) => {
            const date = moment(r.date_creation).format("DD/MM/YY");
            if (date !== lastDay) {
              r.newDay = true;
            }
            if (r.exercice.nom !== lastExo) {
              r.newExo = true;
            }
            lastExo = r.exercice.nom;
            lastDay = date;
            return r;
          });
          this.resultsCount = res.data.count;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/variables.scss" as var;

.seances, .details {
  ul {
    margin-top: 1rem;
    li + li {
      margin-top: 1rem;
    }
    .item {
      text-decoration: none;
      color: black;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background:var.$background-gray;
      padding: 1rem;
      border-radius: var.$border-radius;
      cursor: pointer;
    }
  }
  label {
    font-weight: bold;
  }
}

#perfs {
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    &.new-exo {
      border-top: 1px solid rgba(var.$border-gray, 0.2);
      padding-top: 1rem;
    }
    &.new-day:not(:first-of-type) {
      border-top: 2px solid var.$border-gray;
      padding-top: 1rem;
    }

    > span {
      flex: 1;
      &:first-letter {
        text-transform: capitalize;
      }
      &:nth-of-type(2) {
        flex: 2;
      }
    }
    span:nth-of-type(1) {
      text-align: left;
    }
  }
}
</style>
