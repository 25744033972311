<template>
  <div id="pesee">
    <div
      v-if="formDisplayed"
      class="form"
    >
      <h2>{{form.id ? "Modifier" : "Ajouter"}} une mesure</h2>
      <div>
        <div class="form-field">
          <label>Date&nbsp;:</label>
          <input
            type="date"
            v-model="form.date"
          />
        </div>
        <div class="form-field">
          <label>Taille&nbsp;(cm)&nbsp;:</label>
          <input
            type="number"
            v-model="form.taille"
            placeholder="Ex: 175"
          />
        </div>
        <div class="form-field">
          <label>Age&nbsp;:</label>
          <input
            type="number"
            v-model="form.age"
            placeholder="Ex: 23"
          />
        </div>
        <div class="form-field">
          <label>Poids&nbsp;(kg)&nbsp;:</label>
          <input
            type="number"
            v-model="form.poids"
            placeholder="Ex: 81"
            step="0.01"
            @change="handlePoidsChange"
          />
        </div>
        <div class="form-field">
          <label>Masse grasse&nbsp;(%,&nbsp;kg)&nbsp;:</label>
          <div class="double-field">
            <input
              type="number"
              v-model="form.grasPourc"
              placeholder="Ex: 11%"
              step="0.01"
              @change="handleGrasPourc"
            />
            <input
              type="number"
              v-model="form.grasKg"
              placeholder="Ex: 16kg"
              step="0.01"
              @change="handleGrasKg"
            />
          </div>
        </div>
        <div class="form-field">
          <label>Muscles&nbsp;(%,&nbsp;kg)&nbsp;:</label>
          <div class="double-field">
            <input
              type="number"
              v-model="form.musclesPourc"
              placeholder="Ex: 34%"
              step="0.01"
              @change="handleMusclesPourc"
            />
            <input
              type="number"
              v-model="form.musclesKg"
              placeholder="Ex: 63kg"
              step="0.01"
              @change="handleMusclesKg"
            />
          </div>
        </div>
        <div class="form-field">
          <label>Commentaire&nbsp;:</label>
          <textarea
            class="field"
            v-model="form.commentaire"
            placeholder="Ex: autres mesures"
          />
        </div>
        <div class="form-field">
          <label>Photo&nbsp;:</label>
          <input
            type="file"
            placeholder="Ex: autres mesures"
            @change="fileUpload"
          />
        </div>
      </div>
      <div
        class="button-container"
      >
        <button @click="saveMesure">Enregistrer</button>
      </div>
      <div
        class="button-container"
      >
        <button
          @click="reset"
          class="secondary"
        >
          Annuler
        </button>
      </div>
    </div>
    <div>
      <div
        v-if="!formDisplayed"
        class="button-container"
      >
        <button @click="formDisplayed = true">Ajouter une mesure</button>
      </div>
      <div>
        <ApexChart
          width="100%"
          type="line"
          :options="chartOptions"
          :series="graphSeries"
        />
        <div class="data-selector">
          <button
            class="btn outline"
            :class="{active: dataSelected === 'poids', }"
            @click="dataSelected = 'poids'"
          >
            Poids (kg)
          </button>
          <button
            class="btn outline"
            :class="{active: dataSelected === 'imc', }"
            @click="dataSelected = 'imc'"
          >
            IMC
          </button>
          <button
            class="btn outline"
            :class="{active: dataSelected === 'grasKg', }"
            @click="dataSelected = 'grasKg'"
          >
            MG (kg)
          </button>
          <button
            class="btn outline"
            :class="{active: dataSelected === 'grasPourc', }"
            @click="dataSelected = 'grasPourc'"
          >
            MG (%)
          </button>
          <button
            class="btn outline"
            :class="{active: dataSelected === 'musclesKg', }"
            @click="dataSelected = 'musclesKg'"
          >
            Muscles (kg)
          </button>
          <button
            class="btn outline"
            :class="{active: dataSelected === 'musclesPourc', }"
            @click="dataSelected = 'musclesPourc'"
          >
            Muscles (%)
          </button>
        </div>
      </div>
      <h2>Vos mesures corporelles</h2>
      <ul class="mesures">
        <li
          v-for="mesure in mesures"
          :key="mesure.id"
          @click="mesure.open = !mesure.open"
        >
          <b>{{mesure.date| moment("DD/MM/yyyy")}}&nbsp;:</b> {{mesure.poids}}&nbsp;kg
          <div
            class="collapse"
            v-if="mesure.open"
          >
            <ul>
              <li>
                <u>Taille&nbsp;:</u>
                {{parseInt(mesure.taille, 10, )}}&nbsp;cm
              </li>
              <li>
                <u>Masse grasse&nbsp;:</u>
                {{mesure.grasPourc}}&nbsp;% ({{mesure.grasKg}}&nbsp;kg)
              </li>
              <li>
                <u>Muscles&nbsp;:</u>
                {{mesure.musclesPourc}}&nbsp;% ({{mesure.musclesKg}}&nbsp;kg)
              </li>
              <li v-if="mesure.commentaire">
                <u>Commentaire&nbsp;:</u>
                {{mesure.commentaire}}</li>
              <li>
                <u>IMC&nbsp;:</u>&nbsp;
                <span :style="{color: mesure.imc_color, }">{{mesure.imc}}</span>
              </li>
              <li v-if="mesure.photo">
                <u>Photo&nbsp;:</u>
                <a
                  :href="mesure.photo.img"
                  target="_blank"
                >
                  Voir la photo
                </a>
              </li>
            </ul>
            <MenuDropdown
              :options="[
                {
                  title: 'Modifier',
                  handler: () => handleEdit(mesure, ),
                  icon: 'fa-pen',
                },
              ]"
            />
            <!-- <div class="button-container">
              <button
                class="outline"
                @click="handleEdit(mesure, )"
              >
                Modifier
              </button>
            </div> -->
          </div>
        </li>
      </ul>
      <Pagination
        v-model="currentPage"
        :total-results="resultsCount"
        @paginate="getInfos"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Api from "@/modules/axios";
import { catchError } from "@/utils";
import ApexChart from "vue-apexcharts";
import Pagination from "@/components/Pagination.vue";
import MenuDropdown from "@/components/MenuDropdown.vue";

export default {
  name: "Pesée",
  components: {
    ApexChart,
    Pagination,
    MenuDropdown,
  },
  data() {
    return {
      formDisplayed: true,
      form: {
        date: moment().format("Y-MM-D"),
      },
      addedPhoto: null,
      mesures: [],
      addedMusclesType: null,
      addedGrasType: null,
      dataSelected: "poids",
      currentPage: 1,
      resultsCount: 1,
    };
  },
  watch: {
  },
  computed: {
    graphSeries() {
      return [
        {
          name: "Mesure",
          data: this.mesures.map((m) => [m.date, m[this.dataSelected]]),
        },
      ];
    },
    chartOptions() {
      return {
        chart: {
          foreColor: "#000",
          width: "100%",
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            format: "dd/MM/yy",
          },
        },
        yaxis: {
          min: this.dataSelected === "imc" ? 18 : undefined,
        },
        annotations: this.dataSelected === "imc" ? {
          yaxis: [
            {
              y: 18.5,
              borderColor: "#03AB00",
            },
            {
              y: 25,
              borderColor: "#BF0000",
            },
          ],
        } : {},
      };
    },
  },
  mounted() {
    this.getInfos();
  },
  methods: {
    reset() {
      this.formDisplayed = false;

      const infos = {
        date: moment().format("Y-MM-D"),
      };

      if (this.mesures.length) {
        infos.taille = this.mesures[0].taille;
        infos.age = this.mesures[0].age;
      }
      this.form = infos;
    },
    saveMesure() {
      let promise = Promise.resolve();

      if (this.form.id) {
        promise = Api().patch(`/pesee/${this.form.id}/`, this.form);
      } else {
        promise = Api().post("/pesee/", this.form);
      }
      promise.then((res) => {
        let promisePhoto = Promise.resolve();

        if (this.addedPhoto) {
          const data = new FormData();
          data.append("img", this.addedPhoto);
          data.append("pesee", res.data.id);

          promisePhoto = Api().post("/photo/", data);
        }

        promisePhoto.then(() => {
          this.$toast("Mesure enregistrée.");
          this.getInfos();
          this.reset();
        });
      })
        .catch(catchError);
    },
    getInfos() {
      Api()
        .get(`/pesee/?page=${this.currentPage}`)
        .then((res) => {
          this.mesures = res.data.results.map((m, index) => {
            m.open = index === 0;
            m.taille = parseInt(m.taille, 10);
            m.age = parseInt(m.age, 10);
            m.imc = this.calculIMC(m);
            m.imc_color = this.getIMCColor(m);
            return m;
          });
          this.resultsCount = res.data.count;
          this.reset();
        })
        .catch(catchError);
    },
    calculIMC(mesure) {
      return (
        mesure.poids / ((mesure.taille / 100) ** 2)
      ).toFixed(2);
    },
    getIMCColor(mesure) {
      const res = this.calculIMC(mesure);

      if (res < 18.5) {
        return "blue";
      } else if (res < 25) {
        return "green";
      } else {
        return "red";
      }
    },
    handlePoidsChange() {
      if (this.addedMusclesType === "kg") {
        this.handleMusclesKg();
      } else {
        this.handleMusclesPourc();
      }

      if (this.addedMusclesType === "kg") {
        this.handleGrasKg();
      } else {
        this.handleGrasPourc();
      }
    },
    handleMusclesKg() {
      this.addedMusclesType = "kg";
      this.$set(
        this.form,
        "musclesPourc",
        ((this.form.musclesKg / this.form.poids) * 100).toFixed(2),
      );
    },
    handleMusclesPourc() {
      this.addedMusclesType = "pourc";
      this.$set(
        this.form,
        "musclesKg",
        (this.form.poids * (this.form.musclesPourc / 100)).toFixed(2),
      );
    },
    handleGrasKg() {
      this.addedGrasType = "kg";
      this.$set(
        this.form,
        "grasPourc",
        ((this.form.grasKg / this.form.poids) * 100).toFixed(2),
      );
    },
    handleGrasPourc() {
      this.addedGrasType = "kg";
      this.$set(
        this.form,
        "grasKg",
        (this.form.poids * (this.form.grasPourc / 100)).toFixed(2),
      );
    },
    handleEdit(mesure) {
      this.formDisplayed = true;
      this.form = mesure;
      this.$nextTick(() => {
        document.querySelector(".form").scrollIntoView({ behavior: "smooth" });
      });
    },
    fileUpload(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return false;
      }

      this.addedPhoto = files[0];
      return true;
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

#pesee {
  ul.mesures {
    > li {
      background: var.$background-gray;
      border: 1px solid var.$border-gray;
      border-radius: var.$border-radius;
      padding: 1rem;
      position: relative;
      & + li {
        margin-top: 1rem;
      }
    }
    .v-popover .trigger {
      display: inline-block;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
  }

  .collapse {
    ul {
      margin-top: 1rem;
    }
    li + li {
      margin-top: 0.5rem;
    }
    .blue-link {
      margin: 0;
    }
    .button-container {
      margin: 0;
    }
  }

  .mb {
    margin-top: 1rem;
  }

  .data-selector {
    display: flex;
    // justify-content: space-around;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    button {
      padding: 0.5rem 1rem;
    }
    .active {
      background: var.$red;
      color: white;
    }
  }
}
</style>
